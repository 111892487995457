<template>
  <div class="wrap text-left">
    <h5>{{ title }}</h5>
    <hr />
    <div class="d-flex">
      <img
        :src="
          image && image !== 'null'
            ? `${image}`
            : require('@/assets/profile/empty-image.jpg')
        "
        alt="image"
        class="mr-4"
        width="250px"
        height="250px"
      />
      <p>
        {{
          description === 'null' || !description
            ? $t('herbarium_form_page_no_description')
            : description
        }}
      </p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: { type: String, default: () => '' },
    image: { type: String, default: () => '' },
    description: { type: String, default: () => '' }
  }
}
</script>

<style scoped>
.wrap {
  padding: 2rem;
  background: #ffffff;
  box-shadow: 0px 4px 64px rgba(216, 231, 255, 0.15);
  border-radius: 4px;
}
</style>
