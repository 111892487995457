<template>
  <div>
    <DetailViewPlant
      class="mb-4"
      :title="title"
      :description="choosen.description"
      :image="choosen.image"
    />
    <div class="handbooks mb-5">
      <h5 class="text-left">{{ $t('profile_herbariums_handbooks_plant') }}</h5>
      <b-input-group class="mt-4">
        <b-input-group-prepend>
          <span class="input-group-text">
            <b-icon icon="search" />
          </span>
        </b-input-group-prepend>
        <b-form-input
          v-model.trim="search"
          :placeholder="$t('profile_herbariums_handbooks_search')"
          @input="filter()"
        ></b-form-input>
      </b-input-group>

      <div class="table-wrap">
        <b-table
          id="plant-table"
          responsive
          :busy="isLoading"
          :items="getData"
          :fields="fields"
          class="mt-4"
          :sort-direction="sortDesc ? 'desc' : 'asc'"
          no-local-sorting
          @sort-changed="sortChanged"
          @row-clicked="id => pickItem(id)"
        >
          <template #head()="data">
            <div>
              <span>{{ $t(data.label) }}</span>
            </div>
          </template>
          <template #table-busy>
            <div class="text-center my-5">
              <b-spinner variant="success" label="Text Centered"></b-spinner>
            </div>
          </template>
          <template #cell(edit)="row">
            <Icon
              :type="'edit'"
              :action="() => edit(row.item.id)"
              :access="['editor', 'admin']"
              class="show-when-hovered"
            />
          </template>
          <template v-slot:table-caption>
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalRows"
              size="sm"
              first-number
              last-number
              aria-controls="plant-table"
              class="mt-lg-3"
            />
            <VueJsonToCsv
              :json-data="filteredData"
              :labels="{
                RussianName: {
                  title: $t('profile_herbariums_table_runame').toUpperCase()
                },
                EnglishName: {
                  title: $t('profile_herbariums_table_engname').toUpperCase()
                },
                Family: {
                  title: $t('profile_herbariums_table_family').toUpperCase()
                },
                Genus: {
                  title: $t('profile_herbariums_table_genus').toUpperCase()
                },
                Species: {
                  title: $t('profile_herbariums_table_species').toUpperCase()
                },
                Description: {
                  title: $t('plant_item_page_name_description').toUpperCase()
                },
                SubSpeies: {
                  title: $t(
                    'herbarium_form_page_input_sub_species_label'
                  ).toUpperCase()
                }
              }"
            >
              <b-button variant="success">
                {{ $t('profile_herbariums_table_download') }}
              </b-button>
            </VueJsonToCsv>
          </template>
        </b-table>
      </div>
      <Modal
        :title="$t('herbarium_form_page_title_edit_item')"
        :id-modal="'editModal'"
        :size="'xl'"
      >
        <template #modalBody>
          <PlantsFormPage :id="id" is-edit />
        </template>
      </Modal>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
import PlantsFormPage from '@/components/PlantsFormPage.vue'
import Modal from '@/components/Modal'
import Icon from '@/components/Icon'
import DetailViewPlant from '@/components/Handbooks/DetailViewPlant'
let timer = null
export default {
  components: {
    PlantsFormPage,
    Modal,
    Icon,
    DetailViewPlant,
    VueJsonToCsv
  },
  data() {
    return {
      id: '',
      search: '',
      sortDesc: false,
      sortBy: '',
      currentPage: 1,
      totalRows: 0,
      perPage: 5,
      activeId: 1,
      fields: [
        {
          key: 'ruName',
          label: 'profile_herbariums_table_runame',
          thClass: 'thead_',
          sortable: true
        },
        {
          key: 'enName',
          label: 'profile_herbariums_table_engname',
          thClass: 'thead_',
          sortable: true
        },
        {
          key: 'category.category.laName',
          label: 'profile_herbariums_table_family',
          thClass: 'thead_',
          sortable: true
        },
        {
          key: 'category.laName',
          label: 'profile_herbariums_table_genus',
          thClass: 'thead_',
          sortable: true
        },
        {
          key: 'laName',
          label: 'herbarium_form_page_input_species_label',
          thClass: 'thead_',
          sortable: true
        },
        {
          key: 'edit',
          label: '',
          thClass: 'thead_'
        }
      ],
      items: []
    }
  },
  computed: {
    ...mapGetters('plants', ['getData', 'isLoading', 'getPaginationData']),
    ...mapGetters(['languageTake']),
    choosen: function() {
      return (
        this.getData.find(el => el.id === this.activeId) || {
          ruName: this.$t('profile_herbariums_handbooks_take_plants'),
          laName: this.$t('profile_herbariums_handbooks_take_plants'),
          image: '',
          description: ''
        }
      )
    },
    title: function() {
      if (this.languageTake === 'ru') {
        return this.choosen.ruName
      }
      return this.choosen.laName
    },
    filteredData() {
      return this.getData.map(el => {
        return {
          RussianName: el.ruName ? el.ruName : '-',
          EnglishName: el.enName ? el.enName : '-',
          Family: el.category.category.laName
            ? el.category.category.laName
            : '-',
          Genus: el.category.laName ? el.category.laName : '-',
          Species: el.laName ? el.laName : '-',
          Description: el.description ? el.description : '-',
          SubSpeies: el.subSpecies ? el.subSpecies : '-'
        }
      })
    }
  },
  watch: {
    currentPage: function() {
      this.paginate(
        `/plants?offset=${this.currentPage}&search=${this.search}&sort=${
          this.sortDesc ? 'DESC' : 'ASC'
        }&field=${this.sortBy}`
      )
    }
  },
  async mounted() {
    await this.paginate(`/plants?offset=${this.currentPage}`)
  },
  methods: {
    ...mapActions('plants', ['fetchData']),
    edit(id) {
      this.$root.$emit('bv::show::modal', 'editModal', '#btnShow')
      this.id = id
    },
    async paginate(url) {
      await this.fetchData(url)
      this.totalRows = this.getPaginationData
    },
    filter() {
      if (timer) {
        clearTimeout(timer)
      }
      if (this.search.length >= 2) {
        timer = setTimeout(() => {
          this.paginate(
            `/plants?offset=${this.currentPage}&search=${this.search}`
          )
        }, 1000)
      } else {
        if (this.search.length < 1) {
          timer = setTimeout(() => {
            this.currentPage = 1
            this.sortBy = ''
            this.paginate(`/plants?offset=1`)
          }, 500)
        }
      }
    },
    sortChanged({ sortBy, sortDesc }) {
      this.sortDesc = sortDesc
      this.sortBy = sortBy
      if (this.search.length >= 2) {
        this.paginate(
          `/plants?offset=${this.currentPage}&search=${this.search}&sort=${
            sortDesc ? 'DESC' : 'ASC'
          }&field=${sortBy}`
        )
      } else {
        this.paginate(
          `/plants?offset=${this.currentPage}&sort=${
            sortDesc ? 'DESC' : 'ASC'
          }&field=${sortBy}`
        )
      }
    },
    pickItem({ id }) {
      this.activeId = id
    }
  }
}
</script>
<style lang="scss" scoped>
.handbooks {
  padding: 40px 30px;
  background-color: #fff;
  /deep/.thead_ {
    background: #f6f6f6;
    color: #373a3c;
    font-size: 0.8125rem;
    border: none;
    vertical-align: middle;
    border-radius: 4px 4px 0px 0px;
    padding: 0.4rem 0 0.4rem 2rem;
    text-align: left;
    @media (max-width: 426px) {
      line-height: 1.5;
    }
    &:last-child {
      min-width: 77px;
    }
  }
  /deep/.table-wrap {
    position: relative;
    height: 380px;
    @media (max-width: 426px) {
      height: 320px;
    }
    .b-table {
      caption {
        //position: absolute;
        bottom: 0;
      }
      tbody {
        img {
          cursor: pointer;
        }
        tr {
          &:hover {
            background-color: #f4f8ff;
          }
          &:not(:hover) {
            .show-when-hovered {
              display: none;
            }
          }
          td {
            border-top: none;
            border-bottom: 1px solid #f1f1f1;
            text-align: left;
            padding: 0.9rem 0 0.9rem 2rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 200px;
          }
        }
      }
    }
  }
  /deep/.input-group {
    color: #ababab;
    .input-group-text {
      background: #f7f7f7;
      border: none;
      padding: 0.375rem 0.2rem 0.375rem 0.75rem;
    }
  }
}
</style>
